






























































































































































































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import windowWidth from '@/mixins/windowWidth'
import Pagination from '@/components/Pagination/index.vue'
import Search from '@/components/Search/Search.vue'
import { TheMask, mask } from 'vue-the-mask'
// import { DATE_SORTING } from '@/common/constants'

export default Vue.extend({
  name: 'BusinessProfiles',

  components: {
    Loading,
    TheMask,
    Search,
    Pagination
  },

  directives: {
    mask
  },

  mixins: [
    windowWidth
  ],

  data () {
    return {
      offset: 0,
      selectedSearchMethod: 'phone',
      searchPlaceholder: 'Поиск по номеру телефона',
      limit: 25,
      page: 1,
      loading: false,
      searchValue: '',
      fields: [
        {
          key: 'created_at',
          label: 'Дата создания УЗ'
        },
        {
          key: 'username',
          label: 'Логин'
        },
        {
          key: 'name',
          label: 'Название бизнеса'
        },
        {
          key: 'phone',
          label: 'Основной телефон'
        },
        {
          key: 'profile_data.location.address',
          label: 'Локация бизнеса'
        }
      ]
    }
  },

  computed: {
    ...mapState('profiles', [
      'profiles',
      'profilesCount'
    ]),

    selectedRowsPhones () {
      return this.profiles
        .filter(item => item.selected)
        .map(item => item.phone)
    },

    notActivated () {
      return this.$route.name !== 'profiles-active'
    }
  },

  watch: {
    $route () {
      this.offset = Number(this.$route.query.offset || 0)
      this.searchValue = this.$route.query.search || ''
      this.getProfilesPage()
    },

    searchValue (value) {
      if (!value) this.clearSearchValue()
    },

    selectedSearchMethod (val) {
      switch (val) {
        case 'phone':
          this.searchPlaceholder = 'Поиск по номеру телефона'
          break
        case 'username':
          this.searchPlaceholder = 'Поиск по логину'
          break
        default:
          break
      }

      if (!this.searchValue.trim()) return
      this.getProfilesPage()
    }
  },

  async mounted () {
    this.offset = Number(this.$route.query.offset || 0)
    this.page = Number(this.$route.query.page || 1)
    this.searchValue = this.$route.query.search || ''
    await this.getProfilesPage()
  },

  methods: {
    ...mapActions('profiles', [
      'activate',
      'getProfiles'
    ]),

    // sortingChanged (ctx) {
    //   if (!this.sortByDate.length || DATE_SORTING.descending === this.sortByDate) {
    //     this.sortByDate = DATE_SORTING.ascending
    //   } else {
    //     this.sortByDate = DATE_SORTING.descending
    //   }
    //   this.setRouteQuery('orderBy', this.sortByDate)
    // },

    setRouteQuery (paramName: string, paramValue) {
      // const query = Object.assign({}, this.$route.query)
      // query.offset = val
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },

    async handleSearch () {
      if (!this.searchValue.trim()) {
        this.$refs['search-input'].$el.focus()
        return
      }

      this.offset = 0
      this.setRouteQuery('search', this.searchValue)
    },

    async clearSearchValue () {
      this.searchValue = ''
      this.setRouteQuery('search', '')
      this.offset = 0

      await this.getProfilesPage()
    },

    changeTab (tabNumber) {
      this.page = tabNumber
      this.offset = (this.page - 1) * this.limit
      this.setRouteQuery('page', this.page)
      this.setRouteQuery('offset', this.offset)
    },

    async getProfilesPage () {
      try {
        this.loading = true
        await this.getProfiles({
          isAllowedForBusiness: !this.notActivated,
          filter_field: this.selectedSearchMethod,
          profile_type: 'business',
          filter_value: this.searchValue,
          offset: this.offset,
          limit: this.limit
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    showDetails (profile: any) {
      if (profile.profile_type === 'admin') return
      this.$router.push({ name: 'profile-detail', params: { profileId: profile.id } })
    },

    removeSelectedProfiles () {
      this.profiles.forEach(item => {
        item.selected = false
      })
    },

    async activateSelectedProfiles () {
      try {
        this.loading = true
        await this.activate({ phones: this.selectedRowsPhones })
        if (this.selectedRowsPhones.length > 1) {
          this.$toast.success('Профили успешно активированы')
        } else {
          this.$toast.success('Профиль успешно активирован')
        }
        await this.getProfilesPage()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    archiveSelectedProfiles () {
      console.log('archive')
    },

    hideSelectedProfiles () {
      console.log('hide')
    }
  }
})
